<template>
  <div>
    <section class="section">
      <div class="section-center">
        <div class="columntitle">
          <span> 新闻动态 </span>
          <span> Journalism </span>
          <span>
            <img src="../../assets/image/enterpriseNews/lineimg.png" alt="" />
          </span>
        </div>
        <div class="subtitle">
          <p>有为信通护航“云游非遗·影像展”会议活动直播顺利进行！</p>
          <p>发表时间:2021-06-17 09:50</p>
        </div>
        <div class="imgbox">
          <div class="imgbox-center">
            <img
              src="../../assets/image/enterpriseNews/Imageexhibition/img1.png"
              alt=""
            />
          </div>
        </div>
        <div class="describe">
          <div class="bg">
            <span>
              6月11日，2021年文化和自然遗产日“云游非遗·影像展”“非遗购物节”启动仪式在京举行。文化和旅游部党组书记、部长胡和平出席活动并讲话，部党组成员王晓峰出席活动。
            </span>
          </div>
        </div>
        <div class="imgbox">
          <div class="imgbox-center">
            <img
              src="../../assets/image/enterpriseNews/Imageexhibition/img2.png"
              alt=""
            />
            <img
              src="../../assets/image/enterpriseNews/Imageexhibition/img3.png"
              alt=""
            />
          </div>
        </div>
        <div class="describe">
          <div class="bg">
            <span>
              此次活动，主办方选用华为视频会议设备全程进行视频直播，有为信通为会议活动直播，提供相关设备和技术支持，保障了直播顺利进行，画面清晰、流畅，操作便捷、智能，专业度可圈可点！
              据了解，“云游非遗·影像展”由中国演出行业协会联合腾讯视频、爱奇艺、优酷、抖音、快手、哔哩哔哩、酷狗、微博8家网络平台共同承办。2000余部非遗传承纪录影像、非遗题材纪录片、访谈综艺节目于6月8日至14日在线进行公益性展播。“非遗购物节”由文化和旅游部、商务部、国家乡村振兴局有关司局，共同支持阿里巴巴、京东、抖音、拼多多、唯品会、中国手艺网等网络平台联合举办。活动期间，将有7000余家非遗店铺、6万余种非遗产品参与线上线下销售活动。
            </span>
          </div>
        </div>
        <div class="imgbox">
          <div class="imgbox-center">
            <img
              src="../../assets/image/enterpriseNews/Imageexhibition/img4.png"
              alt=""
            />
          </div>
        </div>
        <div style="margin-bottom:40px;" class="describe">
          <div class="bg">
            <span>
              有为信通是华为全产品线经销商，提供视频会议、协作平板、智能安防相关软硬件设备和技术服务，我们希望能服务更多的客户，为您搭建更加便捷、专业的视频会议系统，更加智能、安全的安防系统，期待与您的合作！
            </span>
          </div>
        </div>


      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.section {
  width: 100%;
  .section-center {
    padding-top: 50px;
    font-size: 18px;
    color: #000;
    max-width: 1466px;
    margin: 0 auto;
    .columntitle {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      color: #6f36eb;
      text-align: left;
      margin-bottom: 40px;
      span {
        font-size: 30px;
        display: inline-block;
      }
      span:nth-child(2) {
        margin: 0 10px 0 14px;
        font-size: 28px;
        color: #ccc;
      }
      span:nth-child(3) {
        img {
          width: 100%;
        }
        max-width: 1160px;
      }
    }
    .subtitle {
      font-family: IOS9W4;
      font-weight: 400;
      padding: 22px 0 20px 24px;
      text-align: left;
      background-color: #f5f5f5;
      p:nth-child(1) {
        height: 30px;
        font-size: 30px;
        color: #333333;
        margin-bottom: 14px;
      }
      p:nth-child(2) {
        height: 20px;
        font-size: 20px;
        color: #999;
      }
    }
    .video-box {
      height: 500px;
      .video {
        width: 900px;
        height: 100%;
      }
    }
    .describe {
      border: 1px dashed #aad4ee;
      border-radius: 4px;
      padding: 4px;
      .bg {
        border-radius: 4px;
        background-color: #f4effd;
        color: #8735e4;
        font-size: 18px;
        padding: 40px 48px 38px 48px;
        line-height: 60px;
        text-align: left;
      }
      // background-color: ;
    }
    .textdescribe {
      text-align: left;
      line-height: 60px;
    }
    .describe1 {
      height: 120px;
      .bg {
        height: 80px;
      }
    }
    .imgbox {
      .imgbox-center {
        margin: 64px auto;
        max-width: 900px;
        .img-title {
          height: 20px;
          color: #333;
        }
        img {
          margin-bottom: 40px;
          width: 100%;
        }
        img:last-child {
          margin: 0 !important;
        }
        // img:nth-child(3) {
        //   margin: 0;
        // }
      }
    }
    .bg-title {
      height: 28px;
      margin: 50px 0 50px 0;
      img {
        width: 100%;
      }
    }
    .bg-subtitle {
      height: 20px;
      margin-bottom: 40px;
      color: #333;
    }
  }
}
</style>